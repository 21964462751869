@import "jit-refresh.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/inter/Inter-roman.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Regular';
}

@font-face {
  font-family: 'Inter var';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/inter/Inter-italic.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Italic';
}

@layer components {
  .text-markdown {
    @apply text-lg lg:text-xl lg:leading-relaxed;
  }

  .text-markdown a {
    @apply font-medium underline;
  }

  .text-markdown p {
    @apply my-6 first:mt-0;
  }

  .text-markdown p code,
  .text-markdown ul code,
  .text-markdown ol code {
    @apply text-base bg-gray-100 p-1 rounded;
  }

  .text-markdown ul {
    @apply list-disc list-outside ml-8 my-6;
  }

  .text-markdown ol {
    @apply list-decimal list-outside ml-8 my-6;
  }

  .text-markdown h1 {
    @apply font-bold mt-12 text-4xl;
  }

  .text-markdown h2 {
    @apply font-bold mt-12 text-3xl;
  }

  .text-markdown h3 {
    @apply font-bold mt-12 text-2xl;
  }

  .text-markdown h4 {
    @apply font-bold mt-12 text-xl;
  }

  .text-markdown img {
    @apply rounded-lg my-12 mx-auto;
  }

  .text-markdown img + em {
    @apply -mt-12 mb-14 block text-center text-base text-gray-600;
  }

  .text-markdown blockquote {
    @apply pl-4 border-l-4 border-gray-200 italic;
  }

  .text-markdown pre {
    @apply text-base p-4 overflow-x-auto;
  }

  .text-markdown .highlighter-rouge {
    @apply my-6;
  }

  .text-markdown .highlight {
    @apply rounded-lg;
  }
}
